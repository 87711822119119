'use client'

import { CustomDivider } from '@design-system/src/components/CustomDivider'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { CustomGrid } from '@design-system/src/components/CustomGrid'
import { Text } from '@design-system/src/components/Text'
import { WysiwygReader } from '@design-system/src/components/WysiwygReader'
import { loadStripe } from '@stripe/stripe-js/pure'
import { useSession } from 'next-auth/react'
import { BlockPubSubscribeCustomPageData } from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import { useSearchParams } from 'next/navigation'
import React, { useMemo, useState } from 'react'
import { API_ROUTES_NEXT_PUBLIC_SITE } from 'src/utils/route-utils'

import {
  getSubscriptionIdForPublication,
  getSubscriptionRecurrence,
  getUserSubscriptionPlan,
  isUserSubscribedToNewsletter,
  isUserSubscribedToPublication,
} from '../../../../_utils/publication-utils'
import { BlockViewProps } from '../../../../_utils/typescript-utils'
import { BlockModal } from '../../../BlockModal'
import { CancelSubscriptionModal } from '../SubscibeShared/CancelSubscriptionModal'
import { CancelationFormInput, CancelationReasonModal } from '../SubscibeShared/CancelationReasonModal'
import { ConfirmSubscriptionCancelModal } from '../SubscibeShared/ConfirmSubscriptionCancelModal'
import { StripePlanCard } from '../SubscribeStripe/StripePlanCard'

export const SubscribeStripe: React.FC<
  BlockViewProps<{
    ShapeOfCustomPropsDerivedFromPageData: BlockPubSubscribeCustomPageData
  }>
> = props => {
  const searchParams = useSearchParams()
  const {
    discountPromotionalText,
    description,
    applyDiscountApiUrl,
    subscribeToNewsletterApiUrl,
    discountCode,
    discountApplicationSuccessText,
    newsletterId,
    createSubscriberWithStripeAccountApiUrl,
    updateSubscriberWithStripeAccountApiUrl,
    stripePublishableKey,
    stripePlans,
    stripeSupportPlans,
    propayPlans,
    connectedSubscriptionSystem,
    disclaimer,
    stripePlanOrderByPriceId,
  } = props.blockCustomData

  const { data: session, status } = useSession()

  // special case where 1 plan but with both Monthly and Yearly options
  const isSpecialCase1PlanWithBothOptions = useMemo(() => {
    if (stripePlanOrderByPriceId?.length !== 2) return false
    const planIdOfFirstPlan = stripePlans.find(
      plan =>
        plan.stripePriceIdMonthly === stripePlanOrderByPriceId[0] ||
        plan.stripePriceIdYearly === stripePlanOrderByPriceId[0],
    )?.productId
    const planIdOfSecondPlan = stripePlans.find(
      plan =>
        plan.stripePriceIdMonthly === stripePlanOrderByPriceId[1] ||
        plan.stripePriceIdYearly === stripePlanOrderByPriceId[1],
    )?.productId
    return planIdOfFirstPlan === planIdOfSecondPlan
  }, [])
  const firstPlanInfo = stripePlanOrderByPriceId
    ? stripePlans.find(
        plan =>
          plan.stripePriceIdMonthly === stripePlanOrderByPriceId[0] ||
          plan.stripePriceIdYearly === stripePlanOrderByPriceId[0],
      )
    : undefined

  const subscribedPlanRecurrence = getSubscriptionRecurrence({
    status,
    session,
    connectedSubscriptionSystem,
    stripePlans,
    stripeSupportPlans,
  })

  const [isCancelReasonModalOpen, setIsOpenCancelReasonModal] = useState(false)
  const [isCancelSubscriptionModalOpen, setIsCancelSubscriptionModalOpen] = useState(false)
  const [subscriptionCancelledModalOpen, setSubscriptionCancelledModalOpen] = useState(false)

  const [thankyouModalOpen, setThankyouModalOpen] = useState(false)
  const [feedbackFormReason, setFeedbackFormReason] = useState<CancelationFormInput>()

  const isUserSubscribed = isUserSubscribedToPublication({
    status,
    session,
    connectedSubscriptionSystem,
    stripePlans,
    stripeSupportPlans,
  })
  const subscriptionId = getSubscriptionIdForPublication({
    status,
    session,
    connectedSubscriptionSystem,
    stripePlans,
    stripeSupportPlans,
  })
  const subscribedUserPlan = getUserSubscriptionPlan({
    session,
    connectedSubscriptionSystem,
    stripePlans,
    stripeSupportPlans,
    propayPlans,
  })
  const isUserSubscribedNewsLetter = isUserSubscribedToNewsletter(status, session, newsletterId)

  return (
    <>
      <CustomFlex
        css={{ maxWidth: '[750px]', margin: 'auto', mb: '$5', px: '$8' }}
        direction="column"
        justify="center"
        align="center">
        <WysiwygReader
          initialValue={description}
          css={{
            color: '$gs12',
            '&p[data-wisiwig-body1="true"]': {
              color: '$gs11',
            },
            textAlign: 'center',
            '& *:last-child': {
              paddingBottom: 0,
              margin: 0,
            },
          }}
        />
      </CustomFlex>
      <CustomGrid
        css={{
          mt: '$6',
          justifyItems: 'center',
          bp1: {
            mt: '$8',
          },
          bp3: {
            justifyItems: stripePlanOrderByPriceId?.length === 3 ? 'unset' : 'center',
            mt: '$10',
          },
        }}
        columns={{
          base: '1',
          bp3: stripePlanOrderByPriceId?.length === 1 ? '1' : stripePlanOrderByPriceId?.length === 2 ? '2' : '3',
        }}
        gap="small">
        {stripePlanOrderByPriceId?.map((priceId, index) => {
          const plan = stripePlans.find(
            plan => plan.stripePriceIdMonthly === priceId || plan.stripePriceIdYearly === priceId,
          )
          if (!plan) return null
          let planType: StripePlanCard['planType'] = plan.stripePriceIdYearly === priceId ? 'yearly' : 'monthly'

          const userIsSubscribedToPlan =
            subscribedUserPlan?.system === 'STRIPE' && subscribedUserPlan.subscription?.productId === plan.productId

          const userIsSubscribedToPlanAndPrice = userIsSubscribedToPlan && subscribedPlanRecurrence === planType

          return (
            <StripePlanCard
              {...plan}
              isTopChoice={index === 1}
              key={plan.name}
              showYearlySavingsInCents={isSpecialCase1PlanWithBothOptions}
              name={isSpecialCase1PlanWithBothOptions ? undefined : plan.name}
              priceSubtitle={
                (planType === 'monthly' ? plan.priceSubtitleMonthly : plan.priceSubtitleYearly) || undefined
              }
              clickSubscribeLinkOverride={
                (planType === 'monthly'
                  ? plan.clickSubscribeLinkOverrideMonthly
                  : plan.clickSubscribeLinkOverrideYearly) || undefined
              }
              subtitle={isSpecialCase1PlanWithBothOptions ? undefined : plan.subtitle || undefined}
              planSummary={isSpecialCase1PlanWithBothOptions ? undefined : plan.planSummary || undefined}
              benefits={isSpecialCase1PlanWithBothOptions ? undefined : plan.benefits}
              status={status}
              loadStripe={loadStripe}
              stripePublishableKey={stripePublishableKey}
              userHasASubscription={isUserSubscribed}
              userIsSubscribedToPlanAndPrice={userIsSubscribedToPlanAndPrice}
              createSubscriberWithStripeAccountApiUrl={createSubscriberWithStripeAccountApiUrl}
              updateSubscriberWithStripeAccountApiUrl={updateSubscriberWithStripeAccountApiUrl}
              planType={planType}
              totalNumberOfPlans={stripePlanOrderByPriceId.length as 1 | 2 | 3}
              css={{
                order: index === 1 ? '[-1]' : index,
                bp1: {
                  order: index === 1 ? '[-1]' : index,
                  flex: index === 1 ? '[1 0 100%]' : '[none]',
                },
                bp3: {
                  order: index === 1 ? 1 : index,
                  flex: 'none',
                },
              }}
            />
          )
        })}
      </CustomGrid>

      {isSpecialCase1PlanWithBothOptions && firstPlanInfo && (
        <CustomFlex
          gap="3"
          direction={'column'}
          css={{
            mt: '$10',
            width: 'fit-content',
            textAlign: 'center',
            marginLeft: '$auto',
            marginRight: '$auto',
          }}>
          {firstPlanInfo.planSummary && (
            <>
              <Text variant="body2">{firstPlanInfo.planSummary}</Text>
              <CustomDivider orientation="horizontal" size="small" css={{ color: '$gs6' }} />
            </>
          )}
          {firstPlanInfo.benefits && <WysiwygReader initialValue={firstPlanInfo.benefits} />}
        </CustomFlex>
      )}

      {isUserSubscribed && status === 'authenticated' && searchParams.get('action') === 'manage-subscription' && (
        <Text
          variant="caption"
          css={{ color: '$pri', margin: 'auto', width: 'fit', mt: '$10', cursor: 'pointer' }}
          onClick={() => setIsOpenCancelReasonModal(true)}>
          Want to cancel your subscription? Click Here.
        </Text>
      )}
      {!!disclaimer?.length && (
        <WysiwygReader
          initialValue={disclaimer}
          css={{
            mt: '$20',
            color: '$gs11',
            '&p[data-wisiwig-body1="true"]': {
              color: '$gs11',
            },
            textAlign: 'center',
          }}
        />
      )}
      <CancelationReasonModal
        open={isCancelReasonModalOpen}
        setOpen={setIsOpenCancelReasonModal}
        setIsCancelSubscriptionModalOpen={setIsCancelSubscriptionModalOpen}
        setFeedbackFormReason={setFeedbackFormReason}
      />
      <CancelSubscriptionModal
        open={isCancelSubscriptionModalOpen}
        setOpen={setIsCancelSubscriptionModalOpen}
        setSubscriptionCancelledModalOpen={setSubscriptionCancelledModalOpen}
        subscriptionId={subscriptionId}
        cancelSubscriptionApiUrl={API_ROUTES_NEXT_PUBLIC_SITE.cancelStripeSubscriptionApiUrl}
        // discountPromotionalText={discountPromotionalText}
        // applyDiscountApiUrl={applyDiscountApiUrl}
        // feedbackFormReason={feedbackFormReason}
        // discountCode={discountCode}
        // discountApplicationSuccessText={discountApplicationSuccessText}
      />
      {isUserSubscribed && status === 'authenticated' && (
        <ConfirmSubscriptionCancelModal
          open={subscriptionCancelledModalOpen}
          setOpen={setSubscriptionCancelledModalOpen}
          subscribeToNewsletterApiUrl={subscribeToNewsletterApiUrl}
          setThankyouModalOpen={setThankyouModalOpen}
          newsletterId={newsletterId}
          subscribed={isUserSubscribedNewsLetter}
          userEmail={session?.user?.email}
        />
      )}

      <BlockModal
        openModal={thankyouModalOpen}
        onCloseModalCallback={setThankyouModalOpen}
        title="Thank You For Signing Up!">
        <Text variant="subtitle1" css={{ color: '$gs12', textAlign: 'center' }}>
          You have successfully signed up for the Newsletter.
        </Text>
      </BlockModal>
    </>
  )
}
